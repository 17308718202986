import { createSelector } from '@reduxjs/toolkit';
import { normalize } from '../../helpers/utils';
import { RootState } from '../../store';
import { catalogApiSlice, catalogItemsAdapter, initialState } from './catalogApiSlice';
import { Category } from './types';

export const selectCatalogItems = catalogApiSlice.endpoints.getCatalogItemsGroup.select(undefined);

export const selectCatalogItemsData = createSelector(
  selectCatalogItems,
  (items) => items.data,
);

export const { selectAll: selectAllCatalogItems } = catalogItemsAdapter.getSelectors(
  (state: RootState) => selectCatalogItemsData(state) ?? initialState,
);

export const selectNormalizedCatalogItems = createSelector(
  selectAllCatalogItems,
  (items) => {
    const normalizedData: Category[] = [];
    normalize(items, normalizedData, 'groups');
    return normalizedData;
  },
);

export const selectCatalogItemById = (id: string) => createSelector(
  selectNormalizedCatalogItems,
  (items) => items.find((item) => item.id === parseInt(id, 10)),
);
