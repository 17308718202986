import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authApiSlice } from '../../services/auth/authApiSlice';

export enum CodeFor {
  authByCode,
  register,
}

interface AuthFormData {
  name?: string;
  email?: string;
  phone?: string;
  login?: string;
  codeFor?: CodeFor;
}

interface AuthModalState {
  isOpen: boolean;
  formType: FormType
  data: AuthFormData;
  isLoading?: boolean;
}

export enum FormType {
  login = 'login',
  registration = 'registration',
  loginByCode = 'loginByCode',
  code = 'code',
}

const initialState: AuthModalState = {
  isOpen: false,
  formType: FormType.login,
  data: {},
  isLoading: false,
};

const authModalSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    openModal: (state) => {
      state.isOpen = !state.isOpen;
    },
    closeModal: (state) => {
      state.isOpen = false;
      state.formType = FormType.login;
    },
    toggleCurrentFormType: (state, action: PayloadAction<FormType>) => {
      state.formType = action.payload;
    },
    setFormData: (state, { payload }: PayloadAction<AuthFormData>) => {
      state.data = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApiSlice.endpoints.loginByCode.matchPending,
      (state) => {
        state.isLoading = true;
      },
    );
    builder.addMatcher(
      authApiSlice.endpoints.loginByCode.matchFulfilled,
      (state) => {
        state.isLoading = false;
      },
    );
    builder.addMatcher(
      authApiSlice.endpoints.loginByCode.matchRejected,
      (state) => {
        state.isLoading = false;
      },
    );
    builder.addMatcher(
      authApiSlice.endpoints.register.matchPending,
      (state) => {
        state.isLoading = true;
      },
    );
    builder.addMatcher(
      authApiSlice.endpoints.register.matchFulfilled,
      (state) => {
        state.isLoading = false;
      },
    );
    builder.addMatcher(
      authApiSlice.endpoints.register.matchRejected,
      (state) => {
        state.isLoading = false;
      },
    );
  },
});

export const {
  openModal,
  closeModal,
  toggleCurrentFormType,
  setFormData,
} = authModalSlice.actions;

export default authModalSlice.reducer;

export const isAuthModalOpen = (state: AuthModalState): boolean => state.isOpen;
