import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Product } from '../../services/catalog/types';
import { CartItem } from '../../services/user/types';
import { userApiSlice } from '../../services/user/userApiSlice';
import { logoutAction } from '../auth/auth';

interface CartState {
  cart: CartItem[];
  synchronized: boolean;
  changed: boolean;
}

export interface AddToCartAction {
  itemId: number;
  qty: number;
  price: number;
  sum: number;
  item: Product;
}

const initialState: CartState = {
  cart: [],
  synchronized: false,
  changed: false,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    synchronizeCart: (state, { payload }: PayloadAction<CartItem[]>) => {
      if (!state.synchronized) {
        payload.forEach((item) => {
          const found = state.cart.find((product) => product.item.id === item.item.id);

          if (!found) {
            state.cart = [...state.cart, item];
          }
        });

        state.synchronized = true;
      }
    },
    clearCart: (state) => {
      state.cart = [];
    },
    addToCart: (state, { payload }: PayloadAction<CartItem>) => {
      const maxQuantity = payload.item.amount;
      const found = state.cart.find((item) => item.item.id === payload.item.id);

      if (!found) {
        state.cart = [...state.cart, payload];
      } else if ((found.qty + payload.qty) <= maxQuantity) {
        const modifiedItem = {
          ...found,
          qty: found.qty + payload.qty,
          sum: found.sum + payload.sum,
        };
        state.cart = [
          ...state.cart.filter((item) => item.item.id !== found.item.id),
          modifiedItem,
        ];
      }

      state.changed = true;
    },
    deleteFromCart: (state, { payload }: PayloadAction<number>) => {
      state.cart = state.cart.filter((item) => item.item.id !== payload);
    },
    changeProductQuantity: (state, { payload }: PayloadAction<{ id: number, direction: '+' | '-' }>) => {
      const foundIndex = state.cart.findIndex((item) => item.item.id === payload.id);

      state.cart = [
        ...state.cart.slice(0, foundIndex),
        {
          ...state.cart[foundIndex],
          qty: payload.direction === '+' ? state.cart[foundIndex].qty + 1 : state.cart[foundIndex].qty - 1,
          sum: payload.direction === '+'
            ? state.cart[foundIndex].sum + state.cart[foundIndex].price
            : state.cart[foundIndex].sum - state.cart[foundIndex].price,
        },
        ...state.cart.slice(foundIndex + 1),
      ];
    },
    resetChanged: (state) => {
      state.changed = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutAction, (state) => {
      state.cart = [];
      state.synchronized = false;
    });
    builder.addMatcher(userApiSlice.endpoints.createOrder.matchFulfilled, (state) => {
      state.cart = [];
      state.synchronized = false;
    });
  },
});

export const {
  synchronizeCart,
  clearCart,
  addToCart,
  deleteFromCart,
  changeProductQuantity,
  resetChanged,
} = cartSlice.actions;

export default cartSlice.reducer;
