import React, { Suspense } from 'react';
import './App.scss';
import {
  BrowserRouter as Router, Routes, Route,
} from 'react-router-dom';
import { Route as RouteType } from './routes/types';
import routes from './routes';
import BreadcrumbsRoute from './components/BreadcrumbsRoute/BreadcrumbsRoute';
import Preloader from './components/Preloader/Preloader';

const createRoute = (data: RouteType[]): React.ReactNode => (
  data.map((route, index) => {
    const Element = route.element;
    return (
      <Route
        // eslint-disable-next-line react/no-array-index-key
        key={`${route.path}-${index}`}
        path={route.path}
        index={route.index ?? false}
        element={route.breadcrumbs ? (
          <BreadcrumbsRoute
            component={route.element}
            path={route.path}
          />
        ) : (
          <Element />
        )}
      >
        {route.children ? createRoute(route.children) : null}
      </Route>
    );
  })
);

const App = () => (
  <Suspense fallback={<Preloader fullScreen />}>
    <Router>
      <Routes>
        {createRoute(routes)}
      </Routes>
    </Router>
  </Suspense>
);

export default App;
