import {
  createEntityAdapter,
  EntityState,
} from '@reduxjs/toolkit';
import apiSlice from '../apiSlice';
import { normalizeToCamelKeys, sortByTwoFields } from '../../helpers/utils';
import {
  BannerInfo,
  Category, Filter, GetProductParams, HeaderInfo, Product, Products,
} from './types';

export const catalogItemsAdapter = createEntityAdapter<Category>();
export const initialState = catalogItemsAdapter.getInitialState();

export const catalogApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCatalogItemsGroup: builder.query<EntityState<Category>, undefined>({
      query: () => 'catalog/groups',
      transformResponse(response: Category[]) {
        const transformData: Category[] = response.map((item) => (
          normalizeToCamelKeys<Category>(item)
        ));
        return catalogItemsAdapter.addMany(initialState, transformData);
      },
    }),
    getCatalogVehicleGroups: builder.query<
    Category[],
    { modelID: string; engineID: string }
    >({
      query: ({ modelID, engineID }) => `catalog/vehicle-groups?model=${modelID}&engine=${engineID}`,
      transformResponse: (response: Category[]) => (
        response.map((item) => normalizeToCamelKeys(item))
      ),
    }),
    getProductsByCatalogVehicleGroupId: builder.query<
    Category[],
    { groupID: string; modelID: string; engineID: string }
    >({
      query: ({ groupID, modelID, engineID }) => `catalog/vehicle-groups/${groupID}/items?model=${modelID}&engine=${engineID}`,
      transformResponse: (response: Category[]) => (
        response.map((item) => normalizeToCamelKeys<Category>(item))
      ),
    }),
    getFiltersByCategoryId: builder.query<Filter[], string>({
      query: (id) => `catalog/groups/${id}/filters`,
      transformResponse: (response: Filter[]) => {
        const normalizedResponse = response.map((item) => normalizeToCamelKeys<Filter>(item));
        return normalizedResponse.map((item) => ({
          ...item,
          values: sortByTwoFields(item.values ?? [], item.type !== 1 ? 'primary' : 'count', item.type !== 1 ? 'sortOrder' : 'name'),
        }));
      },
    }),
    getBestOffers: builder.query<Product[], undefined>({
      query: () => 'catalog/best-offers',
      transformResponse: (response: Product[]): Product[] => (
        response.map((item) => normalizeToCamelKeys<Product>(item))
      ),
    }),
    getProductById: builder.query<Product, GetProductParams>({
      query: ({
        id,
        model,
        engine,
      }) => `catalog/items/${id}${model && engine ? `?model=${model}&engine=${engine}` : ''}`,
      transformResponse: (response): Product => (
        normalizeToCamelKeys<Product>(response)
      ),
    }),
    getSimilarProducts: builder.query<Product[], string | number>({
      query: (id) => `catalog/items/${id}/similar-items`,
      transformResponse: (response: Product[]): Product[] => (
        response.map((item) => normalizeToCamelKeys<Product>(item))
      ),
    }),
    getProducts: builder.query<Products, { id: string, page: string, filter?: string }>({
      query: ({ id, page, filter = '' }) => `catalog/groups/${id}/items?pn=${page}&cnt=12&filter=${filter}`,
      transformResponse: (response): Products => (
        normalizeToCamelKeys<Products>(response)
      ),
      providesTags: ['Products'],
    }),
    getProductsBySearchString: builder.query<Product[], string>({
      query: (vin) => `catalog/items?search-string=${vin}`,
      transformResponse: (response: Product[]) => (
        response.map((item) => normalizeToCamelKeys(item))
      ),
    }),
    getHeaderInfo: builder.query<HeaderInfo, undefined>({
      query: () => 'catalog/header-info',
      transformResponse: (response: HeaderInfo) => normalizeToCamelKeys<HeaderInfo>(response),
    }),
    getBanners: builder.query<BannerInfo[], undefined>({
      query: () => 'catalog/banner-info',
      transformResponse: (response: BannerInfo[]) => {
        if (response) {
          return response.map((item) => normalizeToCamelKeys<BannerInfo>(item));
        }

        return [];
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetCatalogItemsGroupQuery,
  useGetCatalogVehicleGroupsQuery,
  useGetProductsByCatalogVehicleGroupIdQuery,
  useGetFiltersByCategoryIdQuery,
  useGetBestOffersQuery,
  useGetProductByIdQuery,
  useGetSimilarProductsQuery,
  useGetProductsQuery,
  useGetProductsBySearchStringQuery,
  useGetHeaderInfoQuery,
  useGetBannersQuery,
} = catalogApiSlice;
