import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthResponse } from '../../services/auth/types';
import { AuthState, User } from './types';

const initialState: AuthState = {
  isAuth: false,
  token: null,
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logoutAction: (state) => {
      state.isAuth = false;
      state.token = null;
      state.user = null;
    },
    loginAction: (state, { payload }: PayloadAction<AuthResponse>) => {
      state.isAuth = true;
      state.token = payload.accessToken;
      state.user = payload.user;
    },
    setToken: (state, { payload }: PayloadAction<AuthResponse>) => {
      state.token = payload.accessToken;
    },
    setUser: (state, { payload }: PayloadAction<User>) => {
      state.user = payload;
    },
  },
});

export const getIsAuth = (state: AuthState): boolean => state.isAuth;
export const getUser = (state: AuthState): User | null => state.user;
export const getToken = (state: AuthState): string | null => state.token;

export const {
  logoutAction,
  loginAction,
  setToken,
  setUser,
} = authSlice.actions;

export default authSlice.reducer;
