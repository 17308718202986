import authModalSlice from '../features/authModalSlice/authModalSlice';
import cartSlice from '../features/cart/cart';
import authSlice from '../features/auth/auth';
import delayedProductsSlice from '../features/delayedProducts/delayedProductsSlice';
import siteAlertSlice from '../features/siteAlert/siteAlertSlice';

export default {
  authModal: authModalSlice,
  cart: cartSlice,
  auth: authSlice,
  delayed: delayedProductsSlice,
  siteAlert: siteAlertSlice,
};
