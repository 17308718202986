import { createSlice } from '@reduxjs/toolkit';
import { catalogApiSlice } from '../../services/catalog/catalogApiSlice';

interface SiteAlertState {
  closed: boolean;
  text: string;
}

const initialState: SiteAlertState = {
  closed: false,
  text: '',
};

const siteAlertSlice = createSlice({
  name: 'siteAlert',
  initialState,
  reducers: {
    closeAlert: (state) => {
      state.closed = true;
    },
    resetAlertState: (state) => {
      state.closed = false;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      catalogApiSlice.endpoints.getHeaderInfo.matchFulfilled,
      (state, { payload }) => {
        if (state.text !== payload.text) {
          state.text = payload.text;
          state.closed = false;
        }
      },
    );
  },
});

export const {
  closeAlert,
  resetAlertState,
} = siteAlertSlice.actions;

export default siteAlertSlice.reducer;
