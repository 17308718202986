/* eslint-disable @typescript-eslint/no-unsafe-argument */

import { UnitListDetail } from '../services/laximo/types';

/* eslint-disable @typescript-eslint/no-unsafe-member-access,
@typescript-eslint/no-unsafe-assignment */
const highlight = (string: string, value: string) => {
  const stringWithoutSpaces = string.replace(/([^а-яА-Яa-zA-Z0-9])/g, '');
  const regex = new RegExp(value.replace(/([^а-яА-Яa-zA-Z0-9])/g, ''), 'ig');
  const match = stringWithoutSpaces.match(regex);
  if (match && value) {
    const firstCharIndex = stringWithoutSpaces.indexOf(match[0]);
    const matchString = string.slice(firstCharIndex);
    let charIndex = 0;
    let i = 0;
    let str = '';

    while (i < matchString.length) {
      if (matchString[i] === match[0][charIndex]) {
        str += matchString[i];
        charIndex++;

        if (match[0].length === charIndex) break;
      } else {
        str += matchString[i];
      }

      i++;
    }

    return `${string.slice(0, firstCharIndex)}<strong>${str}</strong>${matchString.slice(str.length)}`;
  }

  return string;
};

export default highlight;

export const normalizeToCamelKeys = <T>(item: any, deep = true): T => {
  if (!item) {
    return {} as T;
  }
  const keys = Object.keys(item);
  let res = {};

  keys.forEach((key) => {
    if (deep && Array.isArray(item[key])) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      item[key] = item[key].map((subItem: any) => normalizeToCamelKeys(subItem));
    }

    const normalizeKey = key.split('-').map((keyItem, index) => {
      if (index === 0) {
        return keyItem;
      }

      return keyItem[0].toUpperCase() + keyItem.substring(1);
    }).join('');

    res = {
      ...res,
      [normalizeKey]: item[key as keyof T],
    };
  });

  return res as T;
};

export const normalize = (array: any[], outputArray: any[], field: keyof any): void => {
  array.forEach((item) => {
    if (item[field]) {
      const arr = item[field];
      normalize(arr, outputArray, field);
    }

    outputArray.push(item);
  });
};

export const showWIP = () => window.alert('Work in Progress');

// eslint-disable-next-line no-useless-concat
export const normalizeCost = (num: number) => num.toString().replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + ' ');

/* eslint-disable */
export const laximoResponseNormalizer = (response: any) => {
  const filteredKeys = Object.keys(response).filter((key) => !!response[key]);
  return filteredKeys.reduce((current: any, key: any) => {
    if (Array.isArray(response[key])) {
      return {
        ...current,
        [key]: response[key][0].row,
      }
    }
    return current;
  }, {});
};

export const sortByCodeImage = (arr: UnitListDetail[]) => {
  const codes: string[] = [];
  arr.map((item: UnitListDetail) => {
    if (!codes.includes(item.codeonimage)) {
      codes.push(item.codeonimage);
    }
    return false;
  })

  return codes.reduce((current: any, code: any) => {
    const filtered = arr.filter((item) => item.codeonimage === code);

    if (filtered.length > 1) {
      return [
        ...current,
        {
          ...filtered[0],
          items: filtered,
        }
      ]
    }

    return [
      ...current,
      ...filtered,
    ]

  }, []);
};

export const getRawNumber = (number: string): string => {
  const rawNumber = number.replace(/[^0-9]+/g, '');

  if (rawNumber.length > 10 && rawNumber[0] !== '7') {
    return `7${rawNumber.substring(1)}`;
  }

  return rawNumber;
};

export const getYears = () => {
  const currentYear = new Date().getFullYear()
  const length = currentYear - 2000;
  let year = currentYear;

  return Array.from({ length }).map(() => {
    const value = year;
    year--;

    return value;
  })
}

export const sortByTwoFields = <T>(array: T[], mainField: keyof T, secondaryField: keyof T): T[] => {
  const arrayCopy = [...array];
  return arrayCopy.sort((a, b) => {
    if (a[mainField] === b[mainField]) {
      // @ts-ignore
      const a1 = isNaN(parseInt(a[secondaryField], 10)) ? a[secondaryField] : parseInt(a[secondaryField], 10);
      // @ts-ignore
      const b1 = isNaN(parseInt(b[secondaryField], 10)) ? b[secondaryField] : parseInt(b[secondaryField], 10);
      return a1 > b1 ? 1 : -1;
    }

    return a[mainField] < b[mainField] ? 1 : -1;
  });
}

export const randomID = () => Math.random().toString(36).substr(2, 9);
