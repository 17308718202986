import React from 'react';
import styles from './Preloader.module.scss';

interface Props {
  fullScreen?: boolean;
  className?: string;
}

const Preloader: React.FunctionComponent<Props> = ({ fullScreen = false, className = '' }: Props) => (
  <div className={`${styles.wrapper} ${fullScreen ? styles.fullScreen : ''} ${className}`}>
    <div className={styles.loader}>
      <svg className={styles.circular} viewBox="25 25 50 50">
        <circle className={styles.path} cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10" />
      </svg>
    </div>
  </div>
);

export default Preloader;
