import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { logoutAction } from '../auth/auth';
import { userApiSlice } from '../../services/user/userApiSlice';
import { DelayedProduct } from '../../services/user/types';

interface DelayedProductsState {
  products: DelayedProduct[];
  changed: boolean;
  synchronized: boolean;
}

const initialState: DelayedProductsState = {
  products: [],
  changed: false,
  synchronized: false,
};

const delayedProductsSlice = createSlice({
  name: 'delayedProducts',
  initialState,
  reducers: {
    addToDelayed: (state, { payload }: PayloadAction<DelayedProduct>) => {
      state.products = [...state.products, payload];
      state.changed = true;
    },
    removeFromDelayed: (state, { payload }: PayloadAction<number>) => {
      state.products = state.products.filter((product) => product.item.id !== payload);
    },
    resetChanged: (state) => {
      state.changed = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutAction, (state) => {
      state.products = [];
      state.synchronized = false;
    });
    builder.addMatcher(userApiSlice.endpoints.getDelayed.matchFulfilled, (state, { payload }) => {
      if (!state.synchronized) {
        payload.forEach((item) => {
          const found = state.products.find((product) => product.item.id === item.item.id);

          if (!found) {
            state.products = [...state.products, item];
          }
        });

        state.synchronized = true;
      }
    });
  },
});

export const {
  addToDelayed,
  removeFromDelayed,
  resetChanged,
} = delayedProductsSlice.actions;

export default delayedProductsSlice.reducer;
