import { normalizeToCamelKeys } from '../../helpers/utils';
import apiSlice from '../apiSlice';
import {
  AuthRequest, AuthResponse, ConfirmAuth, ConfirmRegister, RegisterRequest, StartAuthRequest,
} from './types';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    passwordLogin: builder.mutation<AuthResponse, AuthRequest>({
      query: ({ login, password }) => ({
        url: 'auth/password-auth',
        method: 'POST',
        params: { login, password },
        credentials: 'include',
      }),
      transformResponse: (response: AuthResponse) => {
        const normalized = normalizeToCamelKeys<AuthResponse>(response);

        return {
          ...normalized,
          user: normalizeToCamelKeys(normalized.user),
        };
      },
    }),
    register: builder.mutation<null, RegisterRequest>({
      query: ({
        phone,
        email,
        name,
      }) => ({
        url: 'auth/start-register',
        method: 'POST',
        params: { phone, email, name },
        credentials: 'include',
      }),
    }),
    confirmRegister: builder.mutation<AuthResponse, ConfirmRegister>({
      query: ({
        code, name, email, phone,
      }) => ({
        url: 'auth/confirm-register',
        method: 'POST',
        params: {
          code, name, email, phone,
        },
        credentials: 'include',
      }),
      transformResponse: (response: AuthResponse) => {
        const normalized = normalizeToCamelKeys<AuthResponse>(response);

        return {
          ...normalized,
          user: normalizeToCamelKeys(normalized.user),
        };
      },
    }),
    loginByCode: builder.mutation<null, StartAuthRequest>({
      query: ({
        login,
      }) => ({
        url: 'auth/start-auth',
        method: 'POST',
        params: { login },
        credentials: 'include',
      }),
    }),
    confirmAuth: builder.mutation<AuthResponse, ConfirmAuth>({
      query: ({ code, login }) => ({
        url: 'auth/confirm-auth',
        method: 'POST',
        params: { code, login },
        credentials: 'include',
      }),
      transformResponse: (response: AuthResponse) => {
        const normalized = normalizeToCamelKeys<AuthResponse>(response);

        return {
          ...normalized,
          user: normalizeToCamelKeys(normalized.user),
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  usePasswordLoginMutation,
  useRegisterMutation,
  useLoginByCodeMutation,
  useConfirmAuthMutation,
  useConfirmRegisterMutation,
} = authApiSlice;
