import React from 'react';
import { useMatch } from 'react-router-dom';
import { Breadcrumbs, RouteWithBreadCrumb } from '../../routes/types';
import { useAppSelector } from '../../hooks/reduxHooks';
import { selectNormalizedCatalogItems } from '../../services/catalog/selectors';

interface Props {
  component: React.FunctionComponent<RouteWithBreadCrumb>;
  path: string;
}

const BreadcrumbsRoute = ({
  component,
  path,
}: Props): React.FunctionComponentElement<Props> => {
  const Component = component;
  const matches = useMatch(path);
  const categories = useAppSelector((state) => selectNormalizedCatalogItems(state));

  const getBreadcrumbsData = () => {
    const initialBreadCrumbs: Breadcrumbs[] = [
      { name: 'Главная', path: '/' },
    ];

    if (!matches) {
      return initialBreadCrumbs;
    }
    const { pathname, params } = matches;
    const ids = Object.values(params);
    const breadcrumbs: Breadcrumbs[] = ids.map((id, index) => {
      const pathArray = pathname.split(ids[index + 1] ?? '/products');
      const found = categories.find((item) => item.id === parseInt(id ?? '', 10));
      const breadcrumbPath = ids.length > 1 ? pathArray[0] : pathname;
      return {
        path: ids.length > 1 && index === ids.length - 1 ? '' : breadcrumbPath.replace(/\/$/g, ''),
        name: found?.name,
      };
    });

    return initialBreadCrumbs.concat(breadcrumbs);
  };

  const data = getBreadcrumbsData();

  return (
    <div>
      <Component breadcrumbsData={data} />
    </div>
  );
};

export default BreadcrumbsRoute;
